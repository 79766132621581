import React, { Component } from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Annotation from 'src/components/annotation';
import { TranslatableText, TranslatedBlock } from 'src/components/language';
import PDFViewer from 'src/components/pdf-viewer';
import PDFJSBackend from 'src/components/pdfjs-backend';
import pdfEN from '../../../static/pdf/ENSPRYNG_Patient_And_Supporter_Caregiver_Information_Book_EN.pdf';
import pdfFR from '../../../static/pdf/ENSPRYNG_Patient_And_Supporter_Caregiver_Information_Book_FR.pdf';

class PatientVideoPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('patient/info-book');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'ENSPRYNG Patient and Supporter/Caregiver Info Book',
          french: 'Brochure d’information sur ENSPRYNG destinée au patient/soignant',
        }}
        showTab={true}
        className="patient"
      >
        <h2>
          <TranslatableText
            dictionary={{
              english: 'ENSPRYNG Patient and Supporter/Caregiver Info Book',
              french: 'Brochure d’information sur ENSPRYNG destinée au patient/soignant',
            }}
          />
        </h2>

        <TranslatedBlock language="english">
          <PDFViewer backend={PDFJSBackend} src={pdfEN} />
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <PDFViewer backend={PDFJSBackend} src={pdfFR} />
        </TranslatedBlock>

        <Annotation />
      </Layout>
    );
  }
}

export default PatientVideoPage;
